import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import DTAccordion from 'src/components/Accordion'
import { Button, CircularProgress, Box, Typography } from '@mui/material'
import { useListPortfolioImmediately } from 'src/graphql/operations/mutations/portfolio'
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import { MomentDateFormat, formatDateAndTime } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import moment from 'moment'
import { Icon, ModalDialog, Skeleton, TextArea } from 'everchain-uilibrary'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext, Can } from 'src/context/Can'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { PortfolioListRequestResponse } from 'src/data/features/get/portfolio/types'
import { getPortfolioListRequest } from 'src/data/features/get/portfolio/portfolio'
import { RespondPortfolioListRequest } from 'src/data/features/post/portfolio/types'
import { respondPortfolioList } from 'src/data/features/post/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'
import { capitalizeFirstLetter } from 'src/utils/text'

interface ListPortfolioImmediatelyProps {
  portfolio: PortfolioInfoDetail | undefined
  modifyListPortfolioImmediately: boolean | false
}

interface RequestResponseComponentProps {
  title: string
  value?: string
  minWidth: string
}

const ListPortfolioImmediately: React.FC<ListPortfolioImmediatelyProps> = ({
  portfolio,
  modifyListPortfolioImmediately,
}: ListPortfolioImmediatelyProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const { profileClient } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const [respondPortfolioListRequest, setRespondPortfolioListRequest] =
    useState<RespondPortfolioListRequest>()
  const [openRejectListModal, setOpenRejectListModal] = useState(false)
  const [rejectReason, setRejectReason] = useState('')

  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY,
    false,
    false,
    true
  )
  const listDateMoment = moment(portfolio?.listDateUtc)

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const canAproveListRequest = ability.can(
    PermissionCodeAccess.Marketplace_Internal_Approve_Listing_Portfolio,
    'any'
  )

  const reactQueryClient = useQueryClient()

  const { data: listRequestResponse, isFetching: listRequestResponseLoading } =
    useCustomQuery<PortfolioListRequestResponse>(
      ['getPortfolioListRequest', portfolio?.id],
      async () => getPortfolioListRequest(portfolio?.id),
      { enabled: canAproveListRequest && !!portfolio, cacheTime: 0 }
    )

  const { listRequest, loading: loadingListRequest } =
    useListPortfolioImmediately({
      onCompleted: (listRequestData: any) => {
        if (listRequestData) {
          enqueueSnackbar('Portfolio has been listed.', notifySuccess)
        }
      },
      onError: (errorResponse: any) => {
        enqueueSnackbar('Error on list portfolio', notifyError)
      },
    })

  const { isFetching: loadingRespondPortfolioList } = useCustomQuery<any>(
    ['respondPortfolioList', respondPortfolioListRequest],
    async () => {
      if (respondPortfolioListRequest) {
        await respondPortfolioList(respondPortfolioListRequest)
          .then(() => {
            setOpenRejectListModal(false)

            const response = respondPortfolioListRequest.accept
              ? 'accepted'
              : 'rejected'
            enqueueSnackbar(
              `The list portfolio request was ${response}.`,
              notifySuccess
            )

            reactQueryClient.refetchQueries({
              queryKey: ['getPortfolioListRequest'],
            })
          })
          .catch(() => {
            enqueueSnackbar(
              'Error on respond the list portfolio request',
              notifyError
            )
          })
      }
    },
    { enabled: !!respondPortfolioListRequest }
  )

  const handleListPortfolioImmediately = () => {
    if (portfolio?.id) {
      listRequest({
        variables: {
          listRequest: {
            portfolioId: portfolio.id,
          },
        },
      })
    }
  }

  const handleRespondPortfolioList = (accept: boolean) => {
    if (listRequestResponse?.id) {
      setRespondPortfolioListRequest({
        requestId: listRequestResponse?.id,
        accept,
        rejectReason: accept ? undefined : rejectReason,
      })
    }
  }

  const disableRespondActionButtons =
    !listRequestResponse?.id ||
    loadingRespondPortfolioList ||
    !!listRequestResponse?.responseUserId

  return (
    <>
      <DTAccordion
        id="list-portfolio-immediately"
        data-cy="list-portfolio-immediately-accordion"
        title="List Portfolio Immediately"
        icon={<Icon name="Check" />}
        expanded={false}
      >
        <Box
          width="100%"
          alignItems="baseline"
          flexDirection="column"
          justifyContent="space-between"
          display="flex"
          gap={2}
          p={4}
        >
          <Typography display="block" style={{ opacity: 0.6 }}>
            List Scheduled Date/Time: {listDateMoment?.format(momentFormat)}
          </Typography>
          {canAproveListRequest && modifyListPortfolioImmediately && (
            <Skeleton isLoading={listRequestResponseLoading}>
              <Box width="100%">
                {listRequestResponse?.id ? (
                  <Box>
                    <RequestResponseComponent
                      title="Requested by"
                      value={listRequestResponse.requestUserName}
                      minWidth="83px"
                    />
                    <RequestResponseComponent
                      title="Request Date"
                      value={formatDateAndTime(
                        new Date(listRequestResponse.requestDateUtc)
                      )}
                      minWidth="82px"
                    />
                    <RequestResponseComponent
                      title="Status"
                      value={capitalizeFirstLetter(listRequestResponse.status)}
                      minWidth="41px"
                    />
                    <RequestResponseComponent
                      title="Request reason"
                      value={listRequestResponse.requestReason}
                      minWidth="95px"
                    />
                    {listRequestResponse.responseUserId && (
                      <RequestResponseComponent
                        title="Answered by"
                        value={listRequestResponse.responseUserName}
                        minWidth="80px"
                      />
                    )}
                    {listRequestResponse.rejectReason && (
                      <RequestResponseComponent
                        title="Deny reason"
                        value={listRequestResponse.rejectReason}
                        minWidth="80px"
                      />
                    )}
                  </Box>
                ) : (
                  <Typography variant="body1">
                    The seller did not request to list immediately.
                  </Typography>
                )}
                <Box
                  display="flex"
                  width="100%"
                  gap={2}
                  pt={2}
                  justifyContent="end"
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={disableRespondActionButtons}
                    startIcon={
                      loadingRespondPortfolioList &&
                      !respondPortfolioListRequest?.accept && (
                        <CircularProgress size={16} color="primary" />
                      )
                    }
                    onClick={() => setOpenRejectListModal(true)}
                  >
                    Deny
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disableRespondActionButtons}
                    startIcon={
                      loadingRespondPortfolioList &&
                      respondPortfolioListRequest?.accept && (
                        <CircularProgress size={16} color="primary" />
                      )
                    }
                    onClick={() => handleRespondPortfolioList(true)}
                  >
                    Accept
                  </Button>
                </Box>
              </Box>
            </Skeleton>
          )}

          {modifyListPortfolioImmediately && (
            <Can
              do={
                PermissionCodeAccess.MarketPlace_Internal_OpenClosePortfolioBidWindow
              }
              on="any"
            >
              <Button
                data-cy="list-portfolio-immediately-button"
                variant="contained"
                color="primary"
                disabled={loadingListRequest}
                startIcon={
                  loadingListRequest && (
                    <CircularProgress size={16} color="primary" />
                  )
                }
                onClick={handleListPortfolioImmediately}
              >
                List Portfolio Immediately
              </Button>
            </Can>
          )}
        </Box>
      </DTAccordion>
      <ModalDialog
        isOpen={openRejectListModal}
        header="Deny Immediate Listing"
        onClose={() => {
          setOpenRejectListModal(false)
          setRejectReason('')
        }}
        onContinue={() => handleRespondPortfolioList(false)}
        buttonOkText="Deny"
        width="50%"
        isFetching={loadingRespondPortfolioList}
        disableOkButton={rejectReason === ''}
      >
        <Box>
          <TextArea
            placeholder="Deny reason"
            width="100%"
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
          />
        </Box>
      </ModalDialog>
    </>
  )
}

export default ListPortfolioImmediately

const RequestResponseComponent: React.FC<RequestResponseComponentProps> = ({
  title,
  value,
  minWidth,
}: RequestResponseComponentProps) => {
  return (
    <Box display="flex">
      <Typography variant="body1" minWidth={minWidth}>
        {title}:
      </Typography>
      <Typography variant="body1" paddingLeft={1}>
        {value}
      </Typography>
    </Box>
  )
}
