/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import * as Yup from 'yup'

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState, useMemo, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID,
  GET_PORTFOLIO_BUYERS_BY_BUYER_ID,
  GET_APPROVED_AGENCIES,
  GET_PORTFOLIO_SELLERS_BY_BUYER_ID,
} from 'src/graphql/operations/queries/postSale'
import { BusinessInfo, BusinessInfoResponse } from 'src/graphql/models/Business'
import { PortfolioNumberResponse } from 'src/graphql/models/PostSale'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useFormik } from 'formik'
import { TdNoEllipsis } from 'src/styles/layout'
import { HeaderInfos } from 'src/components/Header/styles'
import { clientControlPanelToken } from 'src/configs/apolloConfig'
import {
  AccountPlacementAccountsResponse,
  ApprovedAgenciesData,
  ApprovedAgencies,
  AccountPlacementAccounts,
  BulkAccountPlacementTemplateFileResponse,
} from 'src/graphql/models/AccountPlacement'
import {
  GET_ACCOUNT_PLACEMENT_ACCOUNTS,
  GET_BULK_ACCOUNT_PLACEMENT_TEMPLATE_FILE,
} from 'src/graphql/operations/queries/accountPlacement'
import { useAgencyInviteRequest } from 'src/graphql/operations/mutations/accountPlacement'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { rePhoneNumber } from 'src/utils/constants'
import {
  DataValidation,
  FileParseResult,
  ParseXlsxFile,
} from 'src/utils/parseFile'
import { useDropzone } from 'react-dropzone'
import { GetAccountPlacementFileColumns } from 'src/utils/fileHeaders'
import {
  ACCOUNT_DETAIL,
  ACCOUNT_PLACEMENT,
  ACCOUNT_PLACEMENT_INPUTMETADATA,
} from 'src/routes'
import {
  AccountPlacementSection,
  Content,
  DialogTitleStyled,
  Header,
  UploadItem,
} from './styles'
import AgencyInvitation from './AgencyInvitation'
import { DropUpload } from '../LoadPorfolio/styles'
import {
  DataTable,
  DataTableState,
  Icon,
  textSecondary,
} from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const agencyInviteInitialValues = {
  name: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  contactName: '',
  contactEmailAddress: '',
  contactPhoneNumber: '',
  contactTitle: '',
}

// eslint-disable-next-line max-len
const AccountPlacementMain: React.FC = () => {
  const [initialValues] = useState<any>({})
  const [initialAgencyInviteValues, setInitialAgencyInviteValues] =
    useState<any>(agencyInviteInitialValues)
  const [error, setError] = useState<any>()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [totalDataSize, setTotalDataSize] = useState<number>(5)
  const [accountsData, setAccountsData] = useState<AccountPlacementAccounts[]>()
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [openAgencyInvitationDialog, setOpenAgencyInvitationDialog] =
    useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [disableUI, setDisableUI] = useState(false)
  const { profileBusiness, userPermissions, profileClient } =
    useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyerType = userPermissions.type.toLowerCase() === 'buyer'
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const [file, setFile] = useState<any>(null)
  const [fileParseResult, SetFileParseResult] = useState<FileParseResult>()
  const [placementType, setPlacementType] = useState<string>()
  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
  }, [])
  const [uploadingFile, setUploadingFile] = useState<boolean>()

  const accept =
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: disableUI,
    multiple: false,
  })

  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const agencyInviteFormSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    contactName: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postalCode: Yup.string().required('Required'),
    contactEmailAddress: Yup.string()
      .required('Required')
      .email('Invalid e-mail'),
    contactPhoneNumber: Yup.string()
      .required('Required')
      .matches(rePhoneNumber, 'Telephone number is invalid'),
    contactTitle: Yup.string().required('Required'),
  })

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.buyerSelected) errors.buyerSelected = 'Required'

    return errors
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleFilterClick()
      setSubmitting(false)
    },
  })

  const agencyInviteinputDataForm = useFormik({
    initialValues: initialAgencyInviteValues,
    validationSchema: agencyInviteFormSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setDisableUI(true)
      const {
        name,
        address,
        city,
        state,
        postalCode,
        contactName,
        contactEmailAddress,
        contactPhoneNumber,
        contactTitle,
      } = values
      agencyInviteRequest({
        variables: {
          agencyInviteRequest: {
            name,
            address,
            city,
            state,
            postalCode,
            contactName,
            contactEmailAddress,
            contactPhoneNumber,
            contactTitle,
          },
        },
      })
      setSubmitting(false)
    },
  })

  const [
    getSellersByBuyerId,
    { data: sellerList, loading: loadingSellerList },
  ] = useLazyQuery<BusinessInfoResponse>(GET_PORTFOLIO_SELLERS_BY_BUYER_ID)

  const { data: buyersList, loading: loadingBuyerList } =
    useQuery<BusinessInfoResponse>(GET_PORTFOLIO_BUYERS_BY_BUYER_ID)

  const [
    getPortfolios,
    { data: portfolioList, loading: loadingPortfolioList },
  ] = useLazyQuery<PortfolioNumberResponse>(
    GET_PORTFOLIOS_BY_BUYER_AND_SELLER_ID
  )

  const [
    getApprovedAgencies,
    { data: approvedAgencies, loading: approvedAgenciesLoading },
  ] = useLazyQuery<ApprovedAgencies>(GET_APPROVED_AGENCIES, {
    fetchPolicy: 'no-cache',
    client: clientControlPanelToken,
    variables: {
      clientId: Number(profileClient?.Id),
    },
  })

  const [
    getPortfolioAccounts,
    { data: accountsResponse, loading: loadingAccounts },
  ] = useLazyQuery<AccountPlacementAccountsResponse>(
    GET_ACCOUNT_PLACEMENT_ACCOUNTS,
    {
      variables: {
        portfolioNumber: formCollection.values.pidSelected,
        sellerId: formCollection.values.sellerSelected,
        buyerId: formCollection.values.buyerSelected,
        accountIds: formCollection.values.accountIds,
        kendoPagination: JSON.stringify(gridState),
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setAccountsData(
          accountsResponse?.accountPlacementAccounts?.accounts || []
        )
        setTotalDataSize(
          accountsResponse?.accountPlacementAccounts?.totalOfDataResult ?? 25
        )
      },
    }
  )

  const [getTemplateFile, { loading: loadingTemplateFile }] =
    useLazyQuery<BulkAccountPlacementTemplateFileResponse>(
      GET_BULK_ACCOUNT_PLACEMENT_TEMPLATE_FILE,
      {
        onCompleted: (data) => {
          const byteArray = new Uint8Array(data?.templateData.file)
          const arrayBuffer = byteArray.buffer
          const blob = new Blob([arrayBuffer], {
            type: data?.templateData.fileMime,
          })

          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = data?.templateData.fileName
          a.click()
        },
      }
    )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      const accountsId = accountsData?.map((x) => x.portfolioRowId)
      if (accountsId && accountsId?.length > 0) {
        const allIds = accountsId.concat(accountsSelected)
        const dataSelected = [...new Set([...allIds, ...accountsSelected])]

        if (dataSelected !== undefined && dataSelected.length > 0)
          setAccountsSelected(dataSelected)
      }
    }

    if (e === 'Unselect all rows (current page)') {
      const accountsId = accountsData?.map((x) => x.portfolioRowId)

      const ids = accountsSelected.filter((x) => !accountsId?.includes(x))

      if (ids !== undefined) setAccountsSelected(ids)
    }
  }

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['portfolioRowId']) !==
      undefined
    )
      return true

    return false
  }

  const handleOpenAgencyInvitationDialog = () => {
    setOpenAgencyInvitationDialog(true)
  }

  const onCloseAgencyInvitationDialog = () => {
    agencyInviteinputDataForm.setErrors({})
    setInitialAgencyInviteValues(agencyInviteInitialValues)
    setOpenAgencyInvitationDialog(false)
  }

  const handleAccountPlacement = (plctType: string) => {
    history.push({
      pathname: getStandardUri(ACCOUNT_PLACEMENT_INPUTMETADATA),
      state: {
        accountsSelected,
        agencyId:
          formCollection.values?.agencySelected?.approvedAgencyBusinessId,
        placementType: plctType,
      },
    })
  }

  const handleBulkAccountPlacement = () => {
    setShowUploadDialog(true)
  }

  const handleBuyerSelected = (business: BusinessInfo) => {
    setAccountsSelected([])
    formCollection.setFieldValue('sellerSelected', undefined)
    formCollection.setFieldValue('pidSelected', undefined)
    formCollection.setFieldValue('accountIds', undefined)
    formCollection.setFieldValue('buyerSelected', business)
    getPortfolios({
      variables: {
        sellerId: formCollection.values.sellerSelected
          ? formCollection.values.sellerSelected?.id
          : undefined,
        buyerId: business.id,
      },
    })

    getPortfolioAccounts({
      variables: {
        sellerId: formCollection?.values?.sellerSelected?.id,
        buyerId: business?.id,
        kendoPagination: JSON.stringify(gridState),
      },
    })

    getSellersByBuyerId({
      variables: {
        buyerId: business.id,
      },
    })

    getApprovedAgencies({
      variables: {
        clientId: Number(business?.clientId),
      },
    })
  }

  const handlePidSelected = (portfolioNumber: number) => {
    setAccountsSelected([])
    formCollection.setFieldValue('pidSelected', portfolioNumber)
    getPortfolioAccounts({
      variables: {
        sellerId: formCollection.values.sellerSelected
          ? formCollection.values.sellerSelected.id
          : undefined,
        buyerId: formCollection.values.buyerSelected
          ? formCollection.values.buyerSelected.id
          : undefined,
        portfolioNumber,
        kendoPagination: JSON.stringify(gridState),
      },
    })
  }

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const buyerListMemo = useMemo(() => {
    if (buyersList) {
      if (
        buyersList?.businesses &&
        buyersList?.businesses?.length > 0 &&
        isBuyerType
      ) {
        const profile = buyersList?.businesses.filter((x) =>
          profileBusiness.filter((y) => String(y.Id) === x.id)
        )
        if (profile.length > 0) {
          const currentUser = profile.find(
            (z) => z.name === profileClient?.Name
          )
          handleBuyerSelected(currentUser ?? profile[0])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyersList])

  // eslint-disable-next-line no-unused-vars
  const setFormErrors = useMemo(() => {
    setError(formCollection.errors)
  }, [formCollection.errors])

  const GridColumns: any[] = [
    {
      title: 'Select',
      width: 80,
      show: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              value={props.dataItem['portfolioRowId']}
              onChange={(event) => {
                if (event.target.checked) {
                  setAccountsSelected([
                    ...accountsSelected,
                    props.dataItem['portfolioRowId'],
                  ])
                } else {
                  setAccountsSelected(
                    accountsSelected.filter(
                      (id) => id !== props.dataItem['portfolioRowId']
                    )
                  )
                }
              }}
              checked={handleAddCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'rowIndex',
      title: 'Row Index',
      width: 150,
      show: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      show: true,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      show: true,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
  ]

  const getGridColumns = (): any[] => {
    let gridColumns = GridColumns
    if (isBuyerType) {
      gridColumns = gridColumns.concat([
        {
          field: 'lastName',
          title: 'Last Name',
          show: true,
        },
        {
          field: 'firstName',
          title: 'First Name',
          show: true,
        },
      ])
    }

    gridColumns = gridColumns.concat([
      {
        field: 'portfolioRowId',
        title: 'RowId',
        show: false,
      },
      {
        field: 'portfolioNumber',
        title: 'Portfolio Number',
        show: false,
      },
      {
        field: 'portfolioRowGuid',
        title: 'ECAID',
        show: true,
        width: 300,
      },
    ])

    return gridColumns
  }

  const handleSellerSelected = (business: BusinessInfo) => {
    setAccountsSelected([])
    formCollection.setFieldValue('sellerSelected', business)
    getPortfolios({
      variables: {
        sellerId: business.id,
      },
    })

    getPortfolioAccounts({
      variables: {
        sellerId: business.id,
        buyerId: formCollection.values.buyerSelected?.id,
        kendoPagination: JSON.stringify(gridState),
      },
    })
  }

  const handleAgencySelected = (agency: any) => {
    formCollection.setFieldValue('agencySelected', agency)
  }

  const handleFilterClick = () => {
    formCollection.validateForm()
    setAccountsSelected([])
    getPortfolioAccounts({
      variables: {
        portfolioNumber: formCollection.values.pidSelected,
        accountIds: formCollection.values.accountIds,
        kendoPagination: JSON.stringify(gridState),
      },
    })
  }

  const handleInviteAgencyInformationChange = (name: any, value: any) => {
    setInitialAgencyInviteValues({
      ...initialAgencyInviteValues,
      [name]: value,
    })
  }

  const OnHandleUploadFile = async () => {
    setUploadingFile(true)
    const columnsMetadata = GetAccountPlacementFileColumns()
    const result = await ParseXlsxFile(file, columnsMetadata)

    if (!result.passedValidation) {
      SetFileParseResult(result)
      setUploadingFile(false)
      return
    }

    const { errors, dataConverted } = DataValidation(
      result.data,
      columnsMetadata,
      country
    )

    const validAccounts = dataConverted.filter(
      (x: any) => !errors.some((i) => i.rowIndex === x.rowIndex)
    )

    history.push({
      pathname: getStandardUri(`${ACCOUNT_PLACEMENT}/file-upload/errors`),
      state: {
        allData: result.data,
        allValidData: JSON.stringify(validAccounts),
        errors,
        agencyId:
          formCollection.values?.agencySelected?.approvedAgencyBusinessId,
        placementType,
      },
    })
  }

  const onHandleDownloadTemplateFile = () => {
    getTemplateFile()
  }

  const { agencyInviteRequest, loading: loadingagencyInviteRequest } =
    useAgencyInviteRequest({
      onCompleted: () => {
        enqueueSnackbar(
          'The EverChain Compliance Team has been notified about this new agency invite',
          notifySuccess
        )
        onCloseAgencyInvitationDialog()
        setDisableUI(false)
      },
      onError: () => {
        enqueueSnackbar('Operation failed', notifyError)
        onCloseAgencyInvitationDialog()
        setDisableUI(false)
      },
    })

  const renderSellerSelect = () => {
    return (
      <TextField
        label="Seller"
        select
        id="select-seller"
        error={!!error?.sellerSelected}
        helperText={error?.sellerSelected}
        value={formCollection.values.sellerSelected?.name ?? ''}
        fullWidth
        style={{ minWidth: '250px' }}
      >
        <MenuItem
          value=""
          onClick={() => {
            formCollection.setFieldValue('sellerSelected', undefined)
            formCollection.setFieldValue('buyerSelected', undefined)
            formCollection.setFieldValue('pidSelected', undefined)
            formCollection.setFieldValue('accountIds', undefined)
            setAccountsSelected([])
          }}
        >
          <em>Select seller</em>
        </MenuItem>
        {sellerList?.businesses.map((business) => {
          return (
            <MenuItem
              key={business.id}
              value={business.name}
              onClick={() => {
                handleSellerSelected(business)
              }}
            >
              {business.name}
            </MenuItem>
          )
        })}
      </TextField>
    )
  }
  const renderBuyerSelect = () => {
    return (
      <TextField
        label="Buyer"
        select
        id="select-buyer"
        value={formCollection.values.buyerSelected?.name ?? ''}
        fullWidth
        error={!!error?.buyerSelected}
        helperText={error?.buyerSelected}
        style={{ minWidth: '250px' }}
      >
        <MenuItem
          value=""
          onClick={() => {
            setAccountsSelected([])
          }}
        >
          <em>Select buyer</em>
        </MenuItem>
        {buyersList?.businesses?.map((business: BusinessInfo) => {
          return (
            <MenuItem
              key={business.id}
              value={business.name}
              onClick={() => {
                handleBuyerSelected(business)
              }}
            >
              {business.name}
            </MenuItem>
          )
        })}
      </TextField>
    )
  }

  return (
    <>
      <form onSubmit={formCollection.handleSubmit}>
        <AccountPlacementSection>
          <Header as={Content}>
            <Grid container direction="row">
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <HeaderInfos>
                  <IconButton
                    aria-label="go-back"
                    style={{ color: '#656565' }}
                    className="btn-goback"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    <Icon name="ArrowBack" />
                  </IconButton>

                  <Box ml={2}>
                    <Typography variant="h1" style={{ color: '#656565' }}>
                      Account Placement
                    </Typography>
                  </Box>
                </HeaderInfos>
              </Grid>
            </Grid>
          </Header>
          <Grid container direction="column" style={{ padding: '15px' }}>
            <Grid container direction="row" spacing={3}>
              <Grid item>
                {isSeller ? renderSellerSelect() : renderBuyerSelect()}
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                {isSeller ? renderBuyerSelect() : renderSellerSelect()}
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="PID"
                  select
                  id="select-pid"
                  value={formCollection.values.pidSelected ?? ''}
                  fullWidth
                  style={{ minWidth: '100px' }}
                  disabled={
                    formCollection.values.buyerSelected === undefined &&
                    formCollection.values.sellerSelected === undefined
                  }
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('accountIds', undefined)
                      setAccountsSelected([])
                    }}
                  >
                    <em>Select PID</em>
                  </MenuItem>
                  {portfolioList?.portfoliosNumber.map(
                    (portfolioNumber: number) => {
                      return (
                        <MenuItem
                          key={portfolioNumber}
                          value={portfolioNumber}
                          onClick={() => {
                            handlePidSelected(portfolioNumber)
                          }}
                        >
                          {portfolioNumber}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  label="Account IDs"
                  id="select-LoanIds"
                  value={formCollection.values.accountIds ?? undefined}
                  style={{ minWidth: '250px' }}
                  fullWidth
                  disabled={
                    formCollection.values.pidSelected === undefined &&
                    formCollection.values.buyerSelected === undefined &&
                    formCollection.values.sellerSelected === undefined
                  }
                  onChange={(e) => {
                    formCollection.setFieldValue('accountIds', e.target.value)
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  data-cy="filter-button"
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  Filter
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{
                paddingTop: '20px',
                paddingBottom: '10px',
                paddingLeft: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography>
                  Total of accounts selected: {accountsSelected.length}
                </Typography>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  data-cy="bulk-selection-options-button"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  onClick={handleClick}
                  disabled={disableUI || accountsData?.length == 0}
                >
                  Bulk Selection Options{' '}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                    Select all rows (current page)
                  </MenuItem>
                  <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                    Unselect all rows (current page)
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item style={{ marginBottom: '10px' }}>
                <TextField
                  label="Agency"
                  select
                  id="select-agencies"
                  value={formCollection?.values?.agencySelected?.id ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem value="">
                    <em>Select an Agency</em>
                  </MenuItem>
                  {approvedAgencies?.getApprovedAgencies?.map(
                    (agency: ApprovedAgenciesData) => {
                      return (
                        <MenuItem
                          key={agency?.id}
                          value={agency?.id}
                          onClick={() => {
                            handleAgencySelected(agency)
                          }}
                        >
                          {agency.name}
                        </MenuItem>
                      )
                    }
                  )}
                </TextField>
              </Grid>
              {!isInternal && (
                <Grid item>
                  <Button
                    data-cy="filter-button"
                    color="primary"
                    variant="contained"
                    onClick={handleOpenAgencyInvitationDialog}
                  >
                    Invite Agency
                  </Button>
                </Grid>
              )}
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onHandleDownloadTemplateFile}
                  disabled={loadingTemplateFile}
                >
                  Download Template File
                </Button>
              </Grid>
              {!isInternal && (
                <>
                  <Grid item>
                    <Button
                      data-cy="filter-button"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleAccountPlacement('placed')
                      }}
                      disabled={
                        !(
                          accountsSelected.length > 0 &&
                          formCollection.values.agencySelected
                        )
                      }
                    >
                      Place Accounts
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="filter-button"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleAccountPlacement('recalled')
                      }}
                      disabled={
                        !(
                          accountsSelected.length > 0 &&
                          formCollection.values.agencySelected
                        )
                      }
                    >
                      Recall Accounts
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="filter-button"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setPlacementType('placed')
                        handleBulkAccountPlacement()
                      }}
                      disabled={!formCollection.values.agencySelected}
                    >
                      Upload Accounts to be Placed
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      data-cy="filter-button"
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setPlacementType('recalled')
                        handleBulkAccountPlacement()
                      }}
                      disabled={!formCollection.values.agencySelected}
                    >
                      Upload Accounts to be Recalled
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item style={{ paddingTop: '5px' }}>
              {(loadingSellerList ||
                loadingBuyerList ||
                loadingPortfolioList ||
                loadingAccounts ||
                approvedAgenciesLoading ||
                loadingTemplateFile) &&
                loadingPanel}
              <DataTable
                className="requestCustomTable"
                style={{
                  width: '100%',
                }}
                data={
                  formCollection.values.pidSelected ||
                  formCollection.values.sellerSelected ||
                  formCollection.values.buyerSelected
                    ? accountsData
                    : []
                }
                sortable
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={totalDataSize}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)

                  getPortfolioAccounts({
                    variables: {
                      portfolioNumber: formCollection.values.pidSelected,
                      sellerId: formCollection.values.sellerSelected
                        ? formCollection.values.sellerSelected.id
                        : undefined,
                      buyerId: formCollection.values.buyerSelected
                        ? formCollection.values.buyerSelected.id
                        : undefined,
                      accountIds: formCollection.values.accountIds,
                      kendoPagination: JSON.stringify(e.dataState),
                    },
                  })
                }}
                gridColumns={getGridColumns()}
              />
            </Grid>
          </Grid>
        </AccountPlacementSection>
      </form>
      <Dialog
        open={openAgencyInvitationDialog}
        onClose={onCloseAgencyInvitationDialog}
      >
        {disableUI && <Box>{loadingPanel}</Box>}
        <DialogTitleStyled id="comment-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Agency Invite
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseAgencyInvitationDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <form onSubmit={agencyInviteinputDataForm.handleSubmit}>
            <AgencyInvitation
              agencyInviteinputDataForm={agencyInviteinputDataForm}
              handleChange={handleInviteAgencyInformationChange}
              userCountry={country}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCloseAgencyInvitationDialog}
            disabled={loadingagencyInviteRequest}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loadingagencyInviteRequest}
            onClick={agencyInviteinputDataForm.submitForm}
          >
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showUploadDialog}
        onClose={() => setShowUploadDialog(false)}
        aria-labelledby="form-filter"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Upload File</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowUploadDialog(false)}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <Icon name="Close" />
        </IconButton>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Box>
              {!file ? (
                <DropUpload
                  isDragActive={isDragActive}
                  {...getRootProps()}
                  disabled={disableUI}
                >
                  <input {...getInputProps()} />
                  <div className="upload-placeholder">
                    <Icon name="CloudUpload" className="upload-icon" />{' '}
                    <strong style={{ cursor: 'pointer' }}>Browse </strong> or
                    Drop file here to upload
                  </div>
                </DropUpload>
              ) : (
                <UploadItem>
                  <div className="upload-item-info">
                    <Icon
                      name="Description"
                      fontSize="small"
                      color="primary"
                      className="upload-item-icon"
                    />
                    <Typography
                      variant="body2"
                      color={textSecondary.color}
                      component="span"
                      key={file.name}
                    >
                      {file.name}
                    </Typography>
                  </div>
                  <IconButton
                    aria-label="Clear file selection"
                    onClick={() => {
                      setFile(null)
                      SetFileParseResult(undefined)
                    }}
                    disabled={disableUI}
                  >
                    <Icon name="Delete" fontSize="small" />
                  </IconButton>
                </UploadItem>
              )}
            </Box>
            {!fileParseResult?.passedValidation && (
              <Typography color="error">
                {fileParseResult?.errorMessage}
              </Typography>
            )}
            <Box display="flex" justifyContent="end" mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  OnHandleUploadFile()
                }}
                disabled={uploadingFile || !file}
              >
                {uploadingFile ? (
                  <CircularProgress size={15} color="primary" />
                ) : (
                  'Upload File'
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AccountPlacementMain
