import React, { useCallback, useContext, useState } from 'react'
import { Box, Grid, Button, Typography, IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'
import { DropUpload, UploadItem } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSaveAccountDataMediaFileDetails } from 'src/graphql/operations/mutations/postSale'
import { GlobalContext } from 'src/context/GlobalContext'
import { ConfirmDialog } from 'src/components/Dialogs'
import { BlobServiceClient } from '@azure/storage-blob'
import { DropzoneErrorCodes, AccountCardContainer } from 'src/utils/constants'
import { useLazyQuery } from '@apollo/client'
import { GET_PORTFOLIO_WRITE_BLOB_URL } from 'src/graphql/operations/queries/portfolio'
import LinearProgressWithLabel from 'src/components/ProgressBar/LinearProgressWithLabel'
import { UploadStepper } from '../styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { refetchGetAccountCardMediaFilesLink } from 'src/data/features/get/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'

interface AccountDetailMediaFilesFormProps {
  portfolioId: string | undefined
  portfolioNumber: number | undefined
  portfolioRowGuid: string | undefined
  lenderLoanId: string | undefined
  accountCardMediaFiles?: any
  setUploadInProgress: any
}
const AccountDetailMediaFilesForm: React.FC<
  AccountDetailMediaFilesFormProps
> = ({
  portfolioId,
  portfolioNumber,
  portfolioRowGuid,
  lenderLoanId,
  accountCardMediaFiles,
  setUploadInProgress,
}) => {
  const [mediaFiles, setMediaFiles] = useState<any[]>([])
  const [progress, setProgress] = useState(0)
  const [openConfirmReplaceDialog, setOpenConfirmReplaceDialog] =
    useState<boolean>(false)
  const [loadingSaveAccountCardMediaFile, setLoadingSaveAccountCardMediaFile] =
    useState<boolean>(false)
  const queryClient = useQueryClient()

  const MAX_SIZE_FILE = 524288000 // 500mb
  const onDrop = useCallback((acceptedFiles: any) => {
    setMediaFiles(acceptedFiles)
  }, [])
  const { updateCanBeLoggedOut } = useContext(GlobalContext)

  const handleFileRejected = (data: any) => {
    const message =
      data[0].errors[0].code === DropzoneErrorCodes.INVALID_TYPE_FILE
        ? 'Only .zip files are accepted.'
        : data[0].errors[0].code === DropzoneErrorCodes.FILE_TOO_BIG
        ? 'File is over the 500MB limit.'
        : ''

    enqueueSnackbar(`The file has been rejected. ${message}`, notifyError)
  }

  const fileLinks = accountCardMediaFiles ?? []

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: MAX_SIZE_FILE,
    accept:
      'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip,.zip',
    onDropRejected: handleFileRejected,
  })

  function handleUploadFileRemove(): void {
    setMediaFiles([])
  }
  const { enqueueSnackbar } = useSnackbar()

  const notifyError = notistackOptions('error')
  const notifySuccess = notistackOptions('success')

  const [getWriteBlobURL, { loading: loadingGetWriteBlobURL }] =
    useLazyQuery<any>(GET_PORTFOLIO_WRITE_BLOB_URL, {})

  const {
    saveAccountDataMediaFileDetails,
    loading: loadingSaveAccountCardMediaFileDetails,
  } = useSaveAccountDataMediaFileDetails({
    onCompleted: () => {
      enqueueSnackbar('File uploaded successfully', notifySuccess)
      setMediaFiles([])
      cancelOperation()
      refetchGetAccountCardMediaFilesLink(queryClient)
    },
    onError: (e) => {
      enqueueSnackbar('Error uploading files', notifyError)
      cancelOperation()
    },
  })

  const cancelOperation = () => {
    setLoadingSaveAccountCardMediaFile(false)
    updateCanBeLoggedOut(true)
    setUploadInProgress(false)
  }

  const saveMediaFile = async () => {
    updateCanBeLoggedOut(false)
    let overwrite = false
    if (fileLinks?.length > 0) {
      overwrite =
        fileLinks.filter((e: any) => e.displayName === mediaFiles[0].name)
          .length > 0
    }

    if (overwrite) {
      setOpenConfirmReplaceDialog(true)
    } else {
      handleUploadFile(overwrite)
    }
  }

  const handleClose = () => {
    setOpenConfirmReplaceDialog(false)
  }

  const handleConfirmFileReplace = () => {
    handleClose()
    handleUploadFile(true)
  }

  const handleUploadFile = async (overwrite: any) => {
    const blobName = `${portfolioNumber}/${portfolioRowGuid}/${mediaFiles[0].name}`

    getWriteBlobURL().then(async (data) => {
      if (!data.data.getPortfolioMediaFileBlobUrl) {
        enqueueSnackbar('Error uploading files', notifyError)
        return
      }

      setLoadingSaveAccountCardMediaFile(true)
      const blobStorageClient = new BlobServiceClient(
        data?.data?.getPortfolioMediaFileBlobUrl
      )

      const containerClient =
        blobStorageClient.getContainerClient(AccountCardContainer)

      const blockBlobClient = containerClient.getBlockBlobClient(blobName)

      await blockBlobClient
        .uploadData(mediaFiles[0], {
          blobHTTPHeaders: { blobContentType: 'application/x-zip-compressed' },
          onProgress(fileProgress: any) {
            const percentageUploaded =
              (fileProgress?.loadedBytes / mediaFiles[0].size) * 100
            setProgress(percentageUploaded)
          },
        })
        .then(() => {
          saveAccountDataMediaFileDetails({
            variables: {
              portfolioId,
              portfolioRowGuid,
              lenderLoanId,
              overwrite,
              request: {
                fileName: mediaFiles[0].name,
                contentType: mediaFiles[0].type,
              },
            },
            refetchQueries: ['getAccountCardMediaFilesLink'],
          })
        })
        .catch((error) => {
          cancelOperation()
          enqueueSnackbar('Error uploading files', notifyError)
        })
    })
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <UploadStepper>
            {loadingSaveAccountCardMediaFile ||
            loadingGetWriteBlobURL ||
            loadingSaveAccountCardMediaFileDetails ? (
              <LinearProgressWithLabel value={progress} />
            ) : !mediaFiles.length ? (
              <>
                <DropUpload isDragActive={isDragActive} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="upload-placeholder">
                    <Icon name="CloudUpload" className="upload-icon" />{' '}
                    <strong>Choose File </strong> or drop a file here to upload
                  </div>
                </DropUpload>

                <Typography variant="caption" display="block" gutterBottom>
                  Maximum allowed file size is <strong>500MB</strong>
                </Typography>
              </>
            ) : (
              <UploadItem>
                {mediaFiles.map((file: any, idx) => (
                  <>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />{' '}
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleUploadFileRemove}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </>
                ))}
              </UploadItem>
            )}
          </UploadStepper>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              data-cy="add-attachment-button"
              color="primary"
              variant="contained"
              type="submit"
              disabled={
                !mediaFiles.length ||
                loadingSaveAccountCardMediaFile ||
                loadingGetWriteBlobURL ||
                loadingSaveAccountCardMediaFileDetails
              }
              onClick={() => saveMediaFile()}
              startIcon={<Icon name="Add" fontSize="small" />}
            >
              Add File
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={openConfirmReplaceDialog}
        title="Replace file"
        description={
          <>
            <Typography variant="body1" color={textSecondary.color}>
              {
                ' You already have a file with that name, do you want to replace it?'
              }
            </Typography>
          </>
        }
        closeName="No"
        confirmName="Yes"
        onClose={handleClose}
        onConfirm={handleConfirmFileReplace}
      />
    </>
  )
}

AccountDetailMediaFilesForm.defaultProps = {
  accountCardMediaFiles: [],
}

export default AccountDetailMediaFilesForm
