/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */
import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useSnackbar } from 'notistack'
import { useDropzone } from 'react-dropzone'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PURGED_PORTFOLIOS_INTERVAL } from 'src/utils/constants'

import { AuthContext, Business } from 'src/context/AuthenticationContext'
import {
  GET_PORTFOLIOS_BEING_PURGED,
  GET_INFO_TEMPLATES,
} from 'src/graphql/operations/queries/portfolio'
import { useLazyQuery, useQuery, useApolloClient } from '@apollo/client'
import { PortfoliosBeingPurged } from 'src/graphql/models/PortfolioDetail'
import { InfoTemplate } from 'src/graphql/models/PortfolioTemplates'
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  List,
  ListItem,
} from '@mui/material'
import { useHistory } from 'react-router-dom'
import { PORTFOLIO_VALIDATION } from 'src/routes'
import { ParseXlsxFileDynamicColumns } from 'src/utils/parseFile'

import {
  UploadPorfilioPaper,
  Title,
  UploadStepper,
  UploadStepperHeader,
  GridStyle,
  UploadButton,
  CheckIcon,
  StepItem,
  DropUpload,
  UploadItem,
} from './styles'
import Loader from '../../components/Loader/Loader'
import {
  Colors,
  DatePicker,
  Icon,
  ModalDialog,
  textSecondary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getSellerInfo } from 'src/data/features/get/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'
import { uploadPortfolio } from 'src/data/features/post/portfolio/portfolio'
import { getStandardUri } from 'src/utils/common'

interface UploadPortfolioProps {
  businessList?: Business[]
  setOpenDialog?: (open: boolean) => void
  setOpenSplitDialog?: (open: boolean, stagingData: any) => void
  isModalOpen?: boolean
}

const UploadPortfolio: React.FC<UploadPortfolioProps> = ({
  businessList,
  setOpenDialog = () => {},
  setOpenSplitDialog = () => {},
  isModalOpen,
}: UploadPortfolioProps) => {
  const [assetType, setassetType] = useState<any>([])
  const [business, setBusiness] = useState('')
  const [uploadType, setUploadType] = useState('')
  const [template, setTemplate] = useState('')
  const [cutOffDate, setCutOffDate] = useState<Date | null>(new Date())
  const [skipBalanceValidation, setSkipBalanceValidation] = useState(false)
  const [fileMap, setFileMap] = useState('')
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [openValidation, setOpenValidation] = useState(false)
  const [openNewHeadersModal, setOpenNewHeadersModal] = useState(false)
  const { profileClient } = useContext(AuthContext)
  const [portfolioType, setPortfolioType] = useState(0)

  const [templates, setTemplates] = useState<any>([])
  const [assetTypes, setAssetTypes] = useState<any>([])
  const [templateFiles, setTemplateFiles] = useState<any[]>([])
  const [canSplit, setCanSplit] = useState(false)
  const [removePurge, setRemovePurge] = useState<boolean>(false)
  const [enablePortfolioUpload, setEnablePortfolioUpload] =
    useState<boolean>(false)
  const client = useApolloClient()
  const reactQueryClient = useQueryClient()

  const onDrop = useCallback((acceptedFiles: any) => {
    setTemplateFiles(acceptedFiles)
  }, [])
  const accept =
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    disabled: uploadDisabled,
    multiple: false,
  })

  const { data: sellerInfo, isFetched: sellerInfoFetched } = useCustomQuery(
    ['GetSellerInfo', business],
    async () => getSellerInfo(business),
    {
      enabled: !!business,
    }
  )

  const [businesses, setBusinesses] = useState<any>([])

  const [loadInfoTemplates, { data: infoTemplates }] = useLazyQuery(
    GET_INFO_TEMPLATES,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { enqueueSnackbar } = useSnackbar()

  const { useStagePortfolioSplit } = portfolioMutation

  const {
    isFetching: loadingUploadPortfolio,
    isFetched: fetchedUploadPortfolio,
    isError: errorUploadPortfolio,
  } = useCustomQuery<any>(
    ['uploadPortfolio', enablePortfolioUpload],
    async () =>
      uploadPortfolio({
        portfolioTemplateId: template,
        assetTypes: [assetType],
        file: templateFiles[0],
        cutOffDateUtc: cutOffDate!,
      }),
    {
      enabled: enablePortfolioUpload,
      cacheTime: 0,
    }
  )

  useEffect(() => {
    if (fetchedUploadPortfolio) {
      if (!errorUploadPortfolio) {
        setTemplates([])
        setAssetTypes([])
        enqueueSnackbar('Portfolio uploaded successfully', notifySuccess)
        handleUploadFileRemove()
        setBusiness('')
        setTemplate('')
        setFileMap('')
        setSkipBalanceValidation(false)
        setPortfolioType(0)
        setassetType([])
        setOpenValidation(false)
        client.refetchQueries({
          include: ['GetInfoTemplates'],
        })
        reactQueryClient.refetchQueries({
          queryKey: ['getPortfoliosBeingProcessed'],
        })
      } else {
        enqueueSnackbar('Upload failed', notifyError)
      }
      setOpenDialog(false)
      setUploadDisabled(false)
      setEnablePortfolioUpload(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedUploadPortfolio, errorUploadPortfolio])

  const { stagePortfolioSplit, loading: loadingStagePortfolioSplit } =
    useStagePortfolioSplit({
      onCompleted: (stagePortfolioSplitData) => {
        setOpenDialog(false)
        if (stagePortfolioSplitData?.createPortfolioSplitStaging) {
          setTemplates([])
          setAssetTypes([])
          handleUploadFileRemove()
          setBusiness('')
          setTemplate('')
          setassetType([])
          setFileMap('')
          setSkipBalanceValidation(false)
          setPortfolioType(0)

          setOpenSplitDialog(
            true,
            stagePortfolioSplitData.createPortfolioSplitStaging
          )
        } else {
          enqueueSnackbar('Portfolio Split Staging Failed', notifyError)
        }
      },
      onError: () => {
        enqueueSnackbar('Portfolio Split Staging Failed', notifyError)
      },
    })

  const { data: portfoliosBeingPurgedQueryResult, refetch } =
    useQuery<PortfoliosBeingPurged>(GET_PORTFOLIOS_BEING_PURGED, {
      fetchPolicy: 'cache-and-network',
    })

  const portfoliosBeingPurgedList =
    portfoliosBeingPurgedQueryResult?.portfolioDetailData || []

  const getPortfolioPurgedMessage = () => {
    if (portfoliosBeingPurgedList.length > 1) {
      const ids = portfoliosBeingPurgedList
        .map((x) => x.portfolioNumber)
        .join(', ')

      return `The portfolios ${ids} are being purged. A confirmation e-mail will be sent when complete.`
    }

    return `The portfolio ${portfoliosBeingPurgedList[0].portfolioNumber} is being purged. A confirmation e-mail will be sent when complete.`
  }

  const handleBusinessChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    setBusiness(value)
    setRemovePurge(true)

    const filteredTemplates = infoTemplates.getInfoTemplates.filter(
      (t: InfoTemplate) => t.businessId === value
    )
    setTemplates(
      Array.from(
        new Set(filteredTemplates.map((item: InfoTemplate) => item.templateId))
      )
    )

    setFileMap('')
    setTemplate('')
    setassetType('')
    setSkipBalanceValidation(false)
    setPortfolioType(0)
  }
  const history = useHistory()

  const portfolioCountry =
    profileClient?.Country || process.env.REACT_APP_COUNTRY

  const handleTemplateChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const filteredAssetTypes = infoTemplates.getInfoTemplates.filter(
      (t: InfoTemplate) =>
        t.businessId === business && t.templateId === Number(value)
    )

    const currentTemplate = infoTemplates.getInfoTemplates.filter(
      (t: InfoTemplate) =>
        t.businessId === business && t.templateId === Number(value)
    )[0]

    setPortfolioType(currentTemplate.portfolioType)
    setSkipBalanceValidation(currentTemplate.skipBalanceValidation)

    setAssetTypes(
      Array.from(
        new Set(filteredAssetTypes.map((item: InfoTemplate) => item.assetName))
      )
    )
    setFileMap(currentTemplate.fileMapId)
    setTemplate(value)
    if (filteredAssetTypes.length === 1) {
      setassetType(filteredAssetTypes[0].assetName)
      setUploadDisabled(false)
    } else {
      setassetType('')
    }
  }

  const handleOpenValidation = () => {
    setOpenValidation(true)
  }

  const handleOpenModalNewHeaders = (type: string) => {
    setUploadType(type)
    const portfolioTypeId = infoTemplates.getInfoTemplates.find(
      (x: any) => x.templateId === template
    ).portfolioType

    if (portfolioTypeId === 2 || portfolioTypeId === 5 || portfolioTypeId === 6)
      setOpenNewHeadersModal(true)
    else {
      if (type === 'split') handleStagePortfolioSplit()
      else handleOpenValidation()
    }
  }
  useEffect(() => {
    if (openValidation) {
      handleUploadPortfolio()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openValidation])

  const handleAssetTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setassetType(event.target.value)
    setUploadDisabled(false)
  }

  function handleUploadFileRemove(): void {
    setTemplateFiles([])
  }
  const handleUploadPortfolio = async () => {
    const file = templateFiles[0]
    setUploadDisabled(true)
    if (process.env.REACT_APP_ENABLE_LOAD_PORTFOLIO_VALIDATION) {
      await ParseXlsxFileDynamicColumns(file).then((response) => {
        const params = {
          assetType,
          business,
          template,
          originalFileName: file.name,
          fileMap,
          skipBalanceValidation,
          portfolioType,
          cutOffDate,
        }

        localStorage.setItem('portfolio-validation', JSON.stringify(params))

        history.push({
          pathname: getStandardUri(PORTFOLIO_VALIDATION),
          state: {
            fileData: response.data,
          },
        })
      })
    } else {
      setEnablePortfolioUpload(true)
    }
  }

  const handleStagePortfolioSplit = () => {
    const file = templateFiles[0]
    setUploadDisabled(true)
    stagePortfolioSplit({
      variables: {
        portfolioTemplateId: template,
        sellerId: business,
        file,
        assetTypes: assetType,
        cutOffDateUtc: cutOffDate?.toISOString().split('T')[0],
      },
    })
  }

  useEffect(() => {
    setCanSplit(
      (business && sellerInfoFetched && sellerInfo?.allowPortfolioSplit) ||
        false
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business, sellerInfoFetched])

  useEffect(() => {
    if (businessList && !infoTemplates?.getInfoTemplates.length) {
      loadInfoTemplates()
    } else if (
      businessList &&
      infoTemplates.getInfoTemplates.length &&
      businesses.length === 0
    ) {
      const distinctBusinesses = Array.from(
        new Set(
          infoTemplates.getInfoTemplates.map(
            (item: InfoTemplate) => item.businessId
          )
        )
      )

      setBusinesses(distinctBusinesses)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessList, loadInfoTemplates, infoTemplates])

  useEffect(() => {
    const interval = setInterval(() => {
      if (portfoliosBeingPurgedList.length > 0) refetch()
    }, PURGED_PORTFOLIOS_INTERVAL)

    return () => clearInterval(interval)
  })

  const selectedInfoTemplate = infoTemplates?.getInfoTemplates?.find(
    (f: InfoTemplate) =>
      f.businessId === business && f.templateId === Number(template)
  )

  const cutOffDateTooltipTitle =
    'The Cut-Off Date is the date the portfolio of accounts was cut/pulled from the clients system of record. This is the date the account balances and other account level information in the file are accurate as of.'

  return (
    <>
      <ModalDialog
        id="newHeaders"
        header="New Headers"
        onClose={() => {
          setOpenNewHeadersModal(false)
        }}
        buttonOkText="OK"
        hideCancelButton
        isOpen={openNewHeadersModal}
        width="25%"
        onContinue={() => {
          if (uploadType === 'split') handleStagePortfolioSplit()
          else handleOpenValidation()
          setOpenNewHeadersModal(false)
        }}
      >
        <Typography fontSize={15} fontWeight={700}>
          The Headers listed below will become mandatory by the end of Quarter 3
          of 2024 – Please add these Headers to future extractions, and if you
          need any assistance please reach out to Recovery Success. Thank you
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            listStylePosition: 'inside',
          }}
        >
          <ListItem sx={{ display: 'list-item' }}>
            <Typography>AccountSecured</Typography>
            <Typography>LastDelinquencyDate</Typography>
            <Typography>Pre-Charge Off Principle Balance</Typography>
            <Typography>Pre-Charge Off Interest Balance</Typography>
            <Typography>Pre-Charge Off Fee Balance</Typography>
            <Typography>TotalCreditsSinceChargeOff</Typography>
            <Typography>PostChargeOffInterest</Typography>
          </ListItem>
        </List>
      </ModalDialog>
      {openValidation && <Loader />}
      <UploadPorfilioPaper>
        <Grid
          container
          spacing={0}
          direction={isModalOpen ? 'column' : 'row'}
          alignItems="stretch"
        >
          <GridStyle
            item
            xs={6}
            md={isModalOpen ? 12 : 3}
            lg={isModalOpen ? 12 : 2}
          >
            <UploadStepper>
              <UploadStepperHeader>
                <StepItem skipped completed={Boolean(business)}>
                  {business ? <CheckIcon name="Check" /> : 1}
                </StepItem>
                <Title skipped>Business</Title>
              </UploadStepperHeader>
              <TextField
                id="standard-business"
                select
                value={business}
                onChange={handleBusinessChange}
                fullWidth
              >
                {businesses && businesses.length && businesses.length > 0 ? (
                  businesses.map((option: any) => (
                    <MenuItem key={option} value={option}>
                      {
                        infoTemplates.getInfoTemplates.find(
                          (f: InfoTemplate) => f.businessId === option
                        ).businessName
                      }
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" selected disabled>
                    Select your business
                  </MenuItem>
                )}
              </TextField>
            </UploadStepper>
          </GridStyle>
          <GridStyle
            item
            xs={6}
            md={isModalOpen ? 12 : 3}
            lg={isModalOpen ? 12 : 2}
          >
            <UploadStepper>
              <UploadStepperHeader>
                <StepItem skipped={!!business} completed={Boolean(template)}>
                  {template ? <CheckIcon name="Check" /> : 2}
                </StepItem>
                <Title skipped={!!business}>Template</Title>
                {selectedInfoTemplate?.alertMessages.length > 0 && (
                  <Tooltip
                    title={selectedInfoTemplate.alertMessages.map(
                      (i: string) => (
                        // eslint-disable-next-line react/jsx-key
                        <p>{i}</p>
                      )
                    )}
                  >
                    <Box ml={2}>
                      <Icon
                        name="WarningOutlined"
                        color="orange"
                        fontSize="small"
                      />
                    </Box>
                  </Tooltip>
                )}
              </UploadStepperHeader>
              <TextField
                id="standard-template"
                select
                disabled={!business}
                value={template}
                onChange={handleTemplateChange}
                fullWidth
              >
                {infoTemplates?.getInfoTemplates.length &&
                business &&
                templates?.length &&
                templates?.length > 0 ? (
                  templates.map((option: any) => (
                    <MenuItem key={option} value={option}>
                      {
                        infoTemplates.getInfoTemplates.find(
                          (f: InfoTemplate) =>
                            f.businessId === business && f.templateId === option
                        ).templateName
                      }
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" selected disabled>
                    Select your Template
                  </MenuItem>
                )}
              </TextField>
            </UploadStepper>
          </GridStyle>
          <GridStyle
            item
            xs={6}
            md={isModalOpen ? 12 : 3}
            lg={isModalOpen ? 12 : 2}
          >
            <UploadStepper>
              <UploadStepperHeader>
                <StepItem
                  skipped={!!template}
                  completed={(assetType as any[]).length > 0}
                >
                  {(assetType as any[]).length > 0 ? (
                    <CheckIcon name="Check" />
                  ) : (
                    3
                  )}
                </StepItem>
                <Title skipped={!!template}>Asset Type</Title>
              </UploadStepperHeader>

              <>
                {!assetTypes && template ? (
                  <p>Template doesn't have any asset type</p>
                ) : (
                  <TextField
                    id="select-assettype"
                    select
                    disabled={!template}
                    value={assetType}
                    onChange={handleAssetTypeChange}
                    fullWidth
                  >
                    {infoTemplates?.getInfoTemplates.length &&
                    assetTypes?.length ? (
                      assetTypes.map((option: any) => (
                        <MenuItem key={option} value={option}>
                          {option === 'Bankruptcy' && portfolioCountry === 'UK'
                            ? 'Insolvency'
                            : option}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="" selected disabled>
                        Select the asset type
                      </MenuItem>
                    )}
                  </TextField>
                )}
              </>
            </UploadStepper>
          </GridStyle>
          <GridStyle
            item
            xs={6}
            md={isModalOpen ? 12 : 3}
            lg={isModalOpen ? 12 : 2}
          >
            <UploadStepper>
              <UploadStepperHeader>
                <StepItem
                  skipped={assetType.length}
                  completed={assetType.length && !!cutOffDate}
                >
                  {assetType.length && !!cutOffDate ? (
                    <CheckIcon name="Check" />
                  ) : (
                    4
                  )}
                </StepItem>
                <Title skipped={assetType.length}>Cut-Off Date</Title>
                <Tooltip
                  title={
                    assetType.length ? <p>{cutOffDateTooltipTitle}</p> : ''
                  }
                >
                  <Icon
                    name="Info"
                    fontSize="small"
                    color={Colors.primary}
                    style={{ opacity: !assetType.length ? 0.3 : 1 }}
                  />
                </Tooltip>
              </UploadStepperHeader>
              <DatePicker
                value={cutOffDate}
                onChange={setCutOffDate}
                width="100%"
                disabled={!assetType.length}
              />
            </UploadStepper>
          </GridStyle>
          <GridStyle item xs={12} md={12} lg={isModalOpen ? 12 : 4}>
            <UploadStepper>
              {portfoliosBeingPurgedList.length !== 0 && !removePurge ? (
                <>
                  <UploadStepperHeader>
                    <StepItem
                      skipped={!!template}
                      completed={!!templateFiles.length}
                    >
                      {!!template && !!templateFiles.length ? (
                        <CheckIcon name="Check" />
                      ) : (
                        5
                      )}
                    </StepItem>
                    <Title skipped={!!template}>
                      Portfolio(s) being purged
                    </Title>
                  </UploadStepperHeader>
                  <Typography>{getPortfolioPurgedMessage()}</Typography>
                </>
              ) : (
                <>
                  <UploadStepperHeader>
                    <StepItem
                      skipped={assetType.length && !!cutOffDate}
                      completed={!!templateFiles.length}
                    >
                      {!!template && !!templateFiles.length ? (
                        <CheckIcon name="Check" />
                      ) : (
                        5
                      )}
                    </StepItem>

                    <Title skipped={assetType.length && !!cutOffDate}>
                      Upload your portfolio
                    </Title>

                    {canSplit && (
                      <Box marginRight={2} marginLeft={2}>
                        <UploadButton
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenModalNewHeaders('split')}
                          startIcon={
                            loadingStagePortfolioSplit && (
                              <CircularProgress size={18} color="primary" />
                            )
                          }
                          disabled={
                            !assetType ||
                            !templateFiles.length ||
                            loadingUploadPortfolio ||
                            loadingStagePortfolioSplit
                          }
                        >
                          Split Portfolio File
                        </UploadButton>
                      </Box>
                    )}

                    <UploadButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleOpenModalNewHeaders('')
                      }}
                      startIcon={
                        loadingUploadPortfolio && (
                          <CircularProgress size={15} color="primary" />
                        )
                      }
                      disabled={
                        !assetType ||
                        !templateFiles.length ||
                        loadingUploadPortfolio ||
                        loadingStagePortfolioSplit
                      }
                    >
                      {canSplit ? 'Upload as Single File' : 'Upload File'}
                    </UploadButton>
                  </UploadStepperHeader>
                  {!templateFiles.length ? (
                    <DropUpload
                      isDragActive={isDragActive}
                      {...getRootProps()}
                      disabled={uploadDisabled || !cutOffDate}
                    >
                      <input {...getInputProps()} />
                      <div className="upload-placeholder">
                        <Icon name="CloudUpload" className="upload-icon" />{' '}
                        <strong style={{ cursor: 'pointer' }}>Browse </strong>{' '}
                        or Drop files here to upload
                      </div>
                    </DropUpload>
                  ) : (
                    <UploadItem>
                      {templateFiles.map((file: any, idx) => (
                        <>
                          <div className="upload-item-info">
                            <Icon
                              name="Description"
                              fontSize="small"
                              color="primary"
                              className="upload-item-icon"
                            />{' '}
                            <Typography
                              variant="body2"
                              color={textSecondary.color}
                              component="span"
                              key={file.name}
                            >
                              {file.name}
                            </Typography>
                          </div>
                          <IconButton
                            aria-label="Clear file selection"
                            onClick={() => handleUploadFileRemove()}
                            disabled={uploadDisabled || !cutOffDate}
                          >
                            <Icon name="Delete" fontSize="small" />
                          </IconButton>
                        </>
                      ))}
                    </UploadItem>
                  )}
                </>
              )}
            </UploadStepper>
          </GridStyle>
        </Grid>
      </UploadPorfilioPaper>
    </>
  )
}

UploadPortfolio.defaultProps = {
  businessList: [],
}
export default UploadPortfolio
