/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useQuery } from '@apollo/client'
import { AbilityContext } from 'src/context/Can'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material'
import {
  INTERNAL,
  SELLER,
  BUYER,
  PermissionCodeAccess,
} from 'src/utils/constants'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { UserType } from 'src/graphql/models/User'
import Loader from 'src/components/Loader/Loader'
import { getColumns } from 'src/components/Portfolio/PortfolioColumns'
import { resizeColumnByWidth } from 'src/utils/formatKendoColumns'
import { formatDataToExport, isUkCountry } from 'src/utils/common'
import ExportExcelButton from 'src/components/ExportExcel'

import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioCardGridItems } from 'src/data/features/get/portfolio/portfolio'
import { TitleList, NestedHeader, GridItemEmpty } from './styles'
import UploadPorfolio from '../LoadPorfolio/UploadPorfolio'
import SplitPortfolio from '../LoadPorfolio/SplitPortfolio'
import { getQuickFilter } from './QuickFilters'
import { Paged } from 'src/data/types'
import { PortfolioGridItem } from 'src/data/features/get/portfolio/types'
import {
  Colors,
  DataTable,
  DataTableState,
  Icon,
  ModalDialog,
} from 'everchain-uilibrary'

interface MainPortfolioProps {
  cardType: string
  onClickView: (portfolioId: string) => void
  filters: {}
  userTypeSelected?: string
  setIsLoading: any
  subFilter: any
  setSubFilter: any
}

const gridStateDefaultValues: DataTableState = {
  skip: undefined,
  take: undefined,
  filter: undefined,
  sort: [{ field: 'portfolioNumber', dir: 'desc' }],
}

const MainPortfolio: React.FC<MainPortfolioProps> = ({
  cardType,
  onClickView,
  filters,
  userTypeSelected,
  setIsLoading,
  subFilter,
  setSubFilter,
}) => {
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  const { userPermissions, profileBusiness, profileClient } =
    useContext(AuthContext)
  const ability = useContext(AbilityContext)

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openSplitDialog, setOpenSplitDialog] = useState(false)
  const [splitDialogFilename, setSplitDialogFilename] = useState('')
  const [splitDialogAssetTypes, setSplitDialogAssetTypes] = useState('')
  const [cutOffDate, setCutOffDate] = useState<Date | null>(null)
  const [splitDialogTemplates, setSplitDialogTemplates] =
    useState<any>(undefined)
  const [
    splitDialogTotalNumberOfAccounts,
    setSplitDialogTotalNumberOfAccounts,
  ] = useState(0)
  const [splitDialogSelectedTemplateId, setSplitDialogSelectedTemplateId] =
    useState(0)
  const [splitDialogSellerId, setSplitDialogSellerId] = useState('')
  const [splitDialogStagingId, setSplitDialogStagingId] = useState('')
  const [gridColumns, setGridColumns] = useState<any[]>([])
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const quickFilters = getQuickFilter(userPermissions.type, country)
  const [gridState, setGridState] = useState<DataTableState>(
    gridStateDefaultValues
  )
  const defaultSelection =
    cardType === 'pending-funding'
      ? userPermissions.type === INTERNAL
        ? 'all'
        : userPermissions.type === SELLER
        ? 'seller-funding'
        : 'buyer-funding'
      : 'all'

  const {
    data: cardItemData,
    isFetching,
    isError,
  } = useCustomQuery<Paged<PortfolioGridItem>>(
    [
      'getPortfolioCardGridItems',
      userType?.userType,
      cardType,
      filters,
      gridState,
      subFilter === '' ? defaultSelection : subFilter,
    ],
    async () =>
      getPortfolioCardGridItems(
        userType?.userType,
        cardType,
        filters,
        JSON.stringify(gridState),
        subFilter === '' ? defaultSelection : subFilter
      ),
    {
      enabled:
        !!(userType?.userType && cardType) &&
        ((gridState.take === undefined && cardType !== 'sold') ||
          (cardType === 'sold' &&
            gridState.take !== undefined &&
            gridState?.take > 0)),
      cacheTime: 0,
    }
  )

  const portfolioGridData = cardItemData

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const handleSubFilter = (subfilter: string) => {
    setSubFilter(subfilter)
  }

  const handleLoadPortfolio = (option: boolean) => {
    setOpenDialog(option)
    if (localStorage.getItem('portfolio-validation')) {
      localStorage.removeItem('portfolio-validation')
    }
  }

  const handleCloseSplitDialog = () => {
    setOpenSplitDialog(false)
  }

  const showQuickFilters =
    !isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY) &&
    cardItemData &&
    ((userType?.userType === INTERNAL &&
      (cardType === 'loaded-listed' ||
        cardType === 'pending-agreement' ||
        cardType === 'pending-funding')) ||
      (userType?.userType === SELLER &&
        (cardType === 'loaded-listed' ||
          cardType === 'pending-agreement' ||
          cardType === 'pending-funding')) ||
      (userType?.userType === BUYER &&
        (cardType === 'bid-submitted' ||
          cardType === 'pending-agreement' ||
          cardType === 'pending-funding')))

  const showLoadPortfolio =
    ability.can(
      PermissionCodeAccess.MarketPlace_Basic_UploadPortfolio,
      'any'
    ) &&
    (userPermissions.type === 'Seller' ||
      (userPermissions.isReseller && userTypeSelected === 'Seller'))

  useEffect(() => {
    setIsLoading(isFetching)
  }, [isFetching])

  useEffect(() => {
    setGridColumns(
      getColumns(userType?.userType ?? userPermissions.type, cardType, country)
    )

    setSubFilter('')

    if (cardType === 'sold') {
      setGridState({ ...gridStateDefaultValues, skip: 0, take: 25 })
    } else {
      setGridState(gridStateDefaultValues)
    }
  }, [userType, userPermissions, cardType])

  const getQuickFilterName = (subfilter: string) => {
    return quickFilters
      .find((c) => c.Card === cardType)
      ?.Items.find((i) => i.Key === subfilter)
      ?.Value.toLowerCase()
  }

  useEffect(() => {
    setGridColumns(
      getColumns(
        userType?.userType ?? userPermissions.type,
        cardType,
        country,
        subFilter
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subFilter])

  const showUKQuickFilters =
    isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY) &&
    cardItemData &&
    ((userPermissions.type === INTERNAL &&
      (cardType === 'pending-agreement' || cardType === 'pending-funding')) ||
      (userPermissions.type === SELLER &&
        (cardType === 'pending-agreement' || cardType === 'pending-funding')) ||
      (userPermissions.type === BUYER &&
        (cardType === 'bid-submitted' ||
          cardType === 'pending-agreement' ||
          cardType === 'pending-funding')))

  const minGridWidth = useRef<number>(0)
  const grid = useRef<any>(null)
  const [applyMinWidth, setApplyMinWidth] = useState(false)
  const [gridCurrent, setGridCurrent] = useState(0)

  useEffect(() => {
    if (gridColumns.length > 0) {
      if (grid?.current !== null) {
        window.addEventListener('resize', handleResize)
        let tempMinGridWidth = 0
        gridColumns.map((item: any) =>
          item.title !== undefined
            ? (tempMinGridWidth += resizeColumnByWidth(
                item.title,
                item.width ?? 120,
                item.maxWith
              ))
            : tempMinGridWidth
        )

        minGridWidth.current = tempMinGridWidth
        setGridCurrent(grid?.current?.element?.offsetWidth)
        setApplyMinWidth(
          grid?.current?.element?.offsetWidth < minGridWidth?.current
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardItemData])

  const handleResize = () => {
    if (
      grid?.current?.element?.offsetWidth < minGridWidth.current &&
      !applyMinWidth
    ) {
      setApplyMinWidth(true)
    } else if (grid?.current?.element?.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid?.current?.element?.offsetWidth)
      setApplyMinWidth(false)
    }
  }

  if (isError) {
    return (
      <GridItemEmpty>
        <Icon name="ErrorOutline" />
        <Typography>Oops, empty list</Typography>
      </GridItemEmpty>
    )
  }

  if (isFetching) return <Skeleton />

  return (
    <>
      <NestedHeader>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box display="flex" flexDirection="column" justifyContent="center">
              {cardItemData && (
                <TitleList variant="h2">{`${
                  cardItemData?.data?.length ?? 0
                } PORTFOLIOS`}</TitleList>
              )}
            </Box>
            {(showQuickFilters || showUKQuickFilters) && (
              <Box
                pl={5}
                display="flex"
                flexDirection="row"
                justifyContent="left"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <TitleList variant="h2">{'   Quick Filter: '}</TitleList>
                </Box>

                <Box
                  pl={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <TextField
                    select
                    id="quick-filter"
                    value={subFilter === '' ? defaultSelection : subFilter}
                    fullWidth
                    style={{ minWidth: '200px' }}
                  >
                    {quickFilters
                      .find((f) => f.Card === cardType)
                      ?.Items.map((i) => {
                        return (
                          <MenuItem
                            key={i.Key}
                            value={i.Key}
                            onClick={(): void => {
                              handleSubFilter(i.Key)
                            }}
                          >
                            {i.Value}
                          </MenuItem>
                        )
                      })}
                  </TextField>
                </Box>
              </Box>
            )}
          </Box>

          <Box display="flex">
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {showLoadPortfolio && (
                <Button
                  variant="contained"
                  data-cy="load-portfolio-button"
                  color="primary"
                  startIcon={<Icon name="Publish" fontSize="small" />}
                  onClick={(): void => {
                    handleLoadPortfolio(true)
                  }}
                >
                  Load Portfolio
                </Button>
              )}
            </Box>
            {!isFetching && cardItemData && cardItemData.data.length > 0 && (
              <Box
                p={1}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <ExportExcelButton
                  data={formatDataToExport(
                    cardItemData.data || null,
                    gridColumns,
                    profileClient
                  )}
                  fileName={`Portfolios - (${cardType.replace('-', ' ')}${
                    subFilter !== '' && subFilter !== 'all'
                      ? ` - ${getQuickFilterName(subFilter)}`
                      : ''
                  })`}
                />
              </Box>
            )}
          </Box>
        </Box>
      </NestedHeader>
      {isFetching && !portfolioGridData ? (
        <Box mt={5} width="100%" height={900}>
          <Loader />
        </Box>
      ) : (
        <Grid item xs={9} sm={9} md={12} style={{ cursor: 'pointer' }}>
          <DataTable
            ref={grid}
            sortable
            useFilterMenu
            pageable={
              cardType === 'sold'
                ? { pageSizes: [25, 50, 100, 1000, 10000] }
                : false
            }
            style={{
              height: '90%',
              maxHeight: '65vh',
            }}
            total={portfolioGridData?.totalCount}
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            onRowClick={(e) => {
              if (cardType !== 'lost') onClickView(e.dataItem.portfolioId)
            }}
            data={portfolioGridData?.data}
            onDataStateChange={(e) => setGridState(e.dataState)}
            gridColumns={gridColumns}
          />
        </Grid>
      )}

      <ModalDialog
        isOpen={openDialog}
        header="Load Portfolio"
        onClose={handleCloseDialog}
        showActionButtons={false}
        width="700px"
      >
        <UploadPorfolio
          isModalOpen={true}
          key="upload"
          businessList={profileBusiness}
          setOpenDialog={(open: boolean) => {
            setOpenDialog(open)
          }}
          setOpenSplitDialog={(open: boolean, stagingData: any) => {
            setSplitDialogFilename(stagingData?.fileName)
            setSplitDialogAssetTypes(stagingData?.assetTypes)
            setSplitDialogTemplates(stagingData?.templates)
            setSplitDialogTotalNumberOfAccounts(
              stagingData?.totalNumberOfAccounts
            )
            setSplitDialogSelectedTemplateId(stagingData?.selectedTemplateId)
            setSplitDialogSellerId(stagingData?.sellerId)
            setSplitDialogStagingId(stagingData?.stagingId)
            setCutOffDate(stagingData?.cutOffDateUtc)

            setOpenSplitDialog(open)
          }}
        />
      </ModalDialog>

      <ModalDialog
        isOpen={openSplitDialog}
        header="Split Portfolio"
        onClose={handleCloseSplitDialog}
        showActionButtons={false}
      >
        <Box color={Colors.black}>
          <SplitPortfolio
            key="split"
            assetTypes={splitDialogAssetTypes}
            fileName={splitDialogFilename}
            templates={splitDialogTemplates}
            totalNumberOfAccounts={splitDialogTotalNumberOfAccounts}
            selectedTemplateId={splitDialogSelectedTemplateId}
            sellerId={splitDialogSellerId}
            stagingId={splitDialogStagingId}
            setOpenSplitDialog={(open: boolean) => {
              setOpenSplitDialog(open)
            }}
            cutOffDate={cutOffDate}
          />
        </Box>
      </ModalDialog>
    </>
  )
}

export default MainPortfolio
