/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from 'react'

import {
  PortfolioMediaFileHistory,
  PortfolioMediaFileLink,
  PortfolioMediaFileUri,
} from 'src/graphql/models/PortfolioDetail'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ConvertDateToUTC, formatDateAndTimeCountry } from 'src/utils/date'
import { useLazyQuery } from '@apollo/client'
import { cellCreateBy } from 'src/utils/formatKendoColumns'
import { useDeletePortfolioMediaFile } from 'src/graphql/operations/mutations/postSale'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AzureContainers } from 'src/utils/common'
import Loader from '../../../components/Loader/Loader'
import { getPortfolioMediaFileHistory } from 'src/data/features/get/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { GET_PORTFOLIO_MEDIA_FILE_URI } from 'src/graphql/operations/queries/portfolio'
import { DataTable, Icon, ModalDialog } from 'everchain-uilibrary'
import { ZipIcon } from 'src/components/Icons'

interface PortfolioFileLinksTableProps {
  portfolioId: string
  data?: PortfolioMediaFileLink[] | undefined
  uploadInProgress: boolean
  isInternal: boolean
  canUserUpload: boolean
  refetchMediaFileLinks: () => {}
}

const PortfolioMediaFileLinksTable: React.FC<PortfolioFileLinksTableProps> = ({
  portfolioId,
  data,
  uploadInProgress,
  isInternal,
  canUserUpload,
  refetchMediaFileLinks,
}: PortfolioFileLinksTableProps) => {
  const { profileClient } = useContext<any>(AuthContext)
  const [mediaFileToBeDeleted, setMediaFileToBeDeleted] = useState<any>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')
  const notifySuccess = notistackOptions('success')
  const fileNames = JSON.stringify(data?.map((x) => x.displayName))

  const [getUri, { loading: loadingPortfolioMediaFileUri }] =
    useLazyQuery<PortfolioMediaFileUri>(GET_PORTFOLIO_MEDIA_FILE_URI, {
      onCompleted: (responseData) => {
        const uri = responseData.getPortfolioMediaFileUri ?? ''
        if (uri === '') return
        downloadFile(uri)
        refetchPortfolioMediaFileDownloadHistory()
      },
    })

  const {
    data: portfolioMediaFilesHistory,
    isFetching: loadingGetMediaFileHistory,
    refetch: refetchPortfolioMediaFileDownloadHistory,
  } = useCustomQuery<PortfolioMediaFileHistory[]>(
    ['getPortfolioMediaFileHistory', portfolioId, fileNames],
    async () => getPortfolioMediaFileHistory(portfolioId, fileNames),
    {
      enabled: true,
      cacheTime: 0,
    }
  )

  const groupMediaFileHistory = (
    mediaFiles: PortfolioMediaFileHistory[] | undefined
  ) => {
    return mediaFiles?.reduce((acc: any, currentValue) => {
      const key = currentValue.fileName

      if (!acc[key]) {
        acc[key] = []
      }

      acc[key].push(currentValue)
      return acc
    }, {})
  }

  const groupedMediaFileHistory = groupMediaFileHistory(
    portfolioMediaFilesHistory
  )

  const {
    deleteAccountCardMediaFile: deletePortfolioMediaFile,
    loading: loadingDeletePortfolioMediaFile,
  } = useDeletePortfolioMediaFile({
    onCompleted: () => {
      enqueueSnackbar('File deleted successfully', notifySuccess)
      refetchMediaFileLinks()
    },
    onError: (e) => {
      enqueueSnackbar('Error deleting file', notifyError)
    },
  })

  const onDownload = (mediaFileId: any) => {
    if (isInternal && !canUserUpload) {
      return
    }
    getUri({
      variables: {
        mediaFileId,
        containerId: AzureContainers.Portfolios,
      },
      fetchPolicy: 'network-only',
    })
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const handleDeleteDialogToggle = () => {
    setDeleteDialogOpen(!deleteDialogOpen)
  }

  const handleDeleteMediaFile = (value: any) => {
    setMediaFileToBeDeleted(value)
    handleDeleteDialogToggle()
  }

  const onDelete = () => {
    deletePortfolioMediaFile({
      variables: {
        portfolioMediaFileId: mediaFileToBeDeleted,
      },
    })
  }

  const fileLinkColumns: any[] = [
    {
      title: 'File Name',
      field: 'displayName',
      width: 120,
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            {groupedMediaFileHistory !== undefined ? (
              groupedMediaFileHistory[props.dataItem[props.field]]?.length >
                0 &&
              isInternal &&
              !loadingGetMediaFileHistory ? (
                <Tooltip
                  key="downloadstooltip"
                  title={
                    <table>
                      <thead>
                        <tr key="events">
                          <td align="center">Name</td>
                          <td align="center">Downloaded at</td>
                        </tr>
                      </thead>
                      <thead>
                        {groupedMediaFileHistory[
                          props.dataItem[props.field]
                        ]?.map((event: any, index: any) => (
                          <tr key={event.userName}>
                            <td align="center">{event.userName}</td>
                            <td align="center">
                              {event.downloadedAt
                                ? formatDateAndTimeCountry(
                                    new Date(
                                      ConvertDateToUTC(event.downloadedAt)
                                    ),
                                    profileClient?.Country ||
                                      process.env.REACT_APP_COUNTRY
                                  )
                                : 'N/A'}
                            </td>
                          </tr>
                        ))}
                      </thead>
                    </table>
                  }
                >
                  <Box width={300}>
                    <Button
                      data-cy="file-name-button"
                      disableRipple={true}
                      style={{ backgroundColor: 'transparent' }}
                      startIcon={
                        <Box width={20} height={20}>
                          <ZipIcon width="130%" height="130%" />
                        </Box>
                      }
                      onClick={() => {
                        onDownload(props.dataItem.id)
                      }}
                    >
                      {props.dataItem[props.field]}
                    </Button>
                  </Box>
                </Tooltip>
              ) : (
                <Box width={300}>
                  <Button
                    data-cy="file-name-button"
                    disableRipple={true}
                    style={{ backgroundColor: 'transparent' }}
                    startIcon={
                      <Box width={20} height={20}>
                        <ZipIcon width="130%" height="130%" />
                      </Box>
                    }
                    onClick={() => {
                      onDownload(props.dataItem.id)
                    }}
                  >
                    {props.dataItem[props.field]}
                  </Button>
                </Box>
              )
            ) : (
              <></>
            )}
          </td>
        )
      },
    },
    {
      title: 'Uploaded By',
      field: 'createdByUserName',
      show: true,
      width: 60,
      render: (props: any) => cellCreateBy(props),
    },
    {
      title: 'Uploaded Date',
      field: 'createdUtc',
      show: true,
      width: 80,
      render: (props: any) => {
        return props.dataItem[props.field] ? (
          <td {...props} data-cy="uploaded-date-field">
            <span>
              {props.dataItem[props.field] !== ''
                ? formatDateAndTimeCountry(
                    new Date(ConvertDateToUTC(props.dataItem[props.field])),
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                : 'N/A'}
            </span>
          </td>
        ) : (
          <td data-cy="uploaded-date-field">
            <span>N/A</span>
          </td>
        )
      },
    },
    {
      title: 'Is Deleted',
      field: 'isDeleted',
      show: true,
      width: 50,
      render: (props: any) => {
        return (
          <td {...props} data-cy="is-deleted">
            <span>{props.dataItem[props.field] === true ? 'Yes' : 'No'}</span>
          </td>
        )
      },
    },
    {
      title: 'Actions',
      field: '',
      show: true,
      width: 40,
      alignCenter: true,
      render: (props: any) => {
        return (
          <td {...props}>
            <Box
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              {!props.dataItem['isDeleted'] ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDeleteMediaFile(props.dataItem.id)
                  }}
                >
                  <Icon name="Delete" fontSize="small" />
                </IconButton>
              ) : (
                <IconButton size="small" disabled={true}>
                  <Icon name="Delete" fontSize="small" />
                </IconButton>
              )}
            </Box>
          </td>
        )
      },
    },
  ]

  return (
    <>
      {(loadingPortfolioMediaFileUri ||
        uploadInProgress ||
        loadingGetMediaFileHistory) && <Loader />}

      <DataTable
        style={{ height: '30vh' }}
        scrollable="scrollable"
        data={data}
        gridColumns={fileLinkColumns}
      />

      <ModalDialog
        isOpen={deleteDialogOpen}
        header="Delete media file"
        onClose={handleDeleteDialogToggle}
        onContinue={onDelete}
        isFetching={loadingDeletePortfolioMediaFile}
        buttonOkText="Yes"
        buttonCancelText="No"
      >
        <Grid container direction="column">
          <Grid item>
            <Typography>
              Are you sure you want to delete this media file?
            </Typography>
          </Grid>
        </Grid>
      </ModalDialog>
    </>
  )
}

PortfolioMediaFileLinksTable.defaultProps = {
  data: [],
}

export default PortfolioMediaFileLinksTable
